<template>
  <div class="animateList">
    <div class="box">
      <div ref="con">
        <slot name="animate" :Lists="Lists"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * @param {List,Array} 滚动数据
 * @param {time,Number} 滚动间隔时间
 */
export default {
  name: "animateList",
  props: {
    List: {
      type: Array,
      default: [],
    },
    time: {
      typeof: Number,
      default: 2000,
    },
  },
  data() {
    return {
      Lists: this.List,
      timer: null, //定时器
      con: {},
    };
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      if (this.Lists.length <= 5) {
        return;
      }
      clearInterval(this.timer);
      let con = this.$refs.con.style;
      this.timer = setInterval(() => {
        setTimeout(() => {
          this.Lists.push(this.Lists[0]);
          this.Lists.shift();
          con.marginTop = 0;
          con.transition = "";
        }, 1000);
        con.marginTop = "-60px";
        con.transition = "all 1s";
      }, this.time);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    List: {
      handler(news, old) {
        this.Lists = news;
        this.scroll();
        // console.log(news, 88);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 290px;
  line-height: 60upx;
  overflow: hidden;
}
</style>
