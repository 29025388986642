<template>
  <div>
    <div class="record">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['item', { itema: index == 2 }, { itema: index == 5 }]"
      >
        <div
          v-for="(v, indexs) in num_list"
          :key="indexs"
          class="item items"
          :style="{ ...style(item) }"
        >
          <img :src="v.img" alt="" />
        </div>
      </div>
      <div class="dotimga">
        <img src="../../assets/index/num/dot.png" alt="" />
      </div>
      <div class="dotimgb">
        <img src="../../assets/index/num/dot.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    number: {
      default: "000000000",
    },
  },
  data() {
    return {
      // 父级传入
      quantity: 9, //默认9个
      delayed: true, //从零变化
      //   number: "", //数字
      time: 2000, //过度时间
      timing: "ease", //过度动画速度
      num_list: [
        {
          id: 0,
          img: require("../../assets/index/num/0.png"),
        },
        {
          id: 1,
          img: require("../../assets/index/num/1.png"),
        },
        {
          id: 2,
          img: require("../../assets/index/num/2.png"),
        },
        {
          id: 3,
          img: require("../../assets/index/num/3.png"),
        },
        {
          id: 4,
          img: require("../../assets/index/num/4.png"),
        },
        {
          id: 5,
          img: require("../../assets/index/num/5.png"),
        },
        {
          id: 6,
          img: require("../../assets/index/num/6.png"),
        },
        {
          id: 7,
          img: require("../../assets/index/num/7.png"),
        },
        {
          id: 8,
          img: require("../../assets/index/num/8.png"),
        },
        {
          id: 9,
          img: require("../../assets/index/num/9.png"),
        },
      ],
      num: 0,
    };
  },
  computed: {
    numArr() {
      if (this.num) {
        return (this.num + "").split("");
      } else {
        return new Array(this.number.length).fill(0);
      }
    },
    list() {
      let arr = [];
      let len = this.numArr.length;
      if (this.quantity && this.quantity > len) {
        arr = [...new Array(this.quantity - len).fill(0), ...this.numArr];
      } else {
        arr = this.numArr;
      }
      //   console.log(arr, "==-==");
      return arr;
    },
  },
  methods: {
    // randomNumber() {
    //   // console.log(Math.floor(Math.random() * (999999999 - 1 + 1)) + 1,'===');
    //   this.number = Math.floor(Math.random() * (999999999 - 1 + 1)) + 1;
    // },
    style(e) {
      console.log();
      return {
        transform: `translateY(-${e * 100}%)`,
        transition: this.time + "ms",
        transitionTimingFunction: this.timing,
      };
    },
  },
  created() {
    if (this.delayed) {
      setTimeout(() => {
        this.num = this.number;
      }, 500);
    } else {
      this.num = this.number;
    }
  },
  watch: {
    number: {
      handler(newValue, oldValue) {
        //newValue 新的值，oldValue变化前的值
        // console.log(newValue, oldValue)
        this.num = newValue;
      },
      //   deep:true,
      //   immediate:true
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  touch-action: pan-y;
}
.record {
  display: flex;
  width: 100%;
  position: relative;
}
.item {
  width: 78px;
  height: 141px;
  font-size: 50px;
  color: rgb(109, 160, 255);
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  line-height: 141px;
  overflow: hidden;
}
.items {
  padding-bottom: 20px;
}
.itema {
  margin-right: 40px;
  position: relative;
}
.dotimga {
  position: absolute;
  left: 285px;
  bottom: -4px;
}
.dotimgb {
  position: absolute;
  right: 306px;
  bottom: -4px;
}
</style>

