import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'vue-cookies';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: cookies.get('menu'),
  },
  mutations: {
    setMenu(state, data) {
      state.menu = data
    },
  },
  actions: {
  },
  modules: {
  }
})
