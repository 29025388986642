<template>
  <div class=''></div>
</template> 

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},

  created() {},

  methods: {},

  computed: {},

  watch: {},

};
</script>

<style lang='scss' scoped>

</style>