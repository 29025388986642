
import service from "./service";
import cookies from 'vue-cookies';


//  url 请求路径
//  params 参数
//  sToken 是否需要token 默认需要
//  isDadaType get请求传参方式

export default {
  get(url, params, isToken = true, isDadaType = true) {

    const config = {
      method: 'get',
      url: url,
    }
    if (params) {
      if (isDadaType) {
        config.url = `${url}/${params}`;
      } else {
        config.params = params;
      }
    }
    if (isToken) {
      config.headers = {
        'X-Authorization': cookies.get('token')
      }
    }
    return service(config);
  },
  post(url, params, isToken = true) {

    const config = {
      method: 'post',
      url: url,
    }
    if (params) {
      config.data = params;
    }
    if (isToken) {
      config.headers = {
       'X-Authorization': cookies.get('token')
      }
    }
    return service(config);
  }

};

