<template>
  <div>
    <download-excel
      class="export-excel-wrapper"
      :data="dataForm"
      :fields="fields"
      :header="title"
      :name="name"
    >
      <slot></slot>
    </download-excel>

    <!--  dataForm  需要导出的数据
        fields 导出的字段名称
        title 表头
        name 文件名称 -->
  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
export default {
  name: "",
  props: {
    dataForm: {},
    fields: {},
    title: {
      default: "标题",
    },
    name: {
      default: "表格",
    },
  },

  components: { downloadExcel },
  data() {
    return {};
  },
  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {},
};
</script>

<style lang='scss' scoped>
</style>