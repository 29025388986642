import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './http/api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import VueCookies from 'vue-cookies';
import md5 from 'js-md5'
import exportExcel from './components/exportExcel.vue'



Vue.component('exportExcel', exportExcel)

Vue.use(ElementUI);
Vue.use(VueCookies)


Vue.config.productionTip = false
Vue.prototype.$api = api;


Vue.prototype.$echarts = echarts;
Vue.prototype.$md5 = md5
Vue.prototype.$http = ''

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
