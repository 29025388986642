<template>
  <div class="marquee_box" ref="boxsize">
    <div class="marquee_text" ref="boxtext">
      {{ article }}
    </div>
  </div>
</template>
<script>
// 每隔200毫秒滚动的距离
let top = 0;
// 累计共滚动的距离
let countTop = 0;
export default {
  props: {
    content: {
      typeof: String,
      default: '',
    },
    time: {
      typeof: Number,
      default: 2500,
    },
  },
  data() {
    return {
      article: "", //文章
      timer: "",
    };
  },
  mounted() {},
  created() {
    // 每次进入界面时，先清除之前的所有定时器，然后启动新的定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    scroll() {
      let h = parseInt(window.getComputedStyle(this.$refs.boxtext).height);
      console.log(h, "===");
      if (h <= 105) {
        return;
      }
      this.timer = setInterval(() => {
        // 每次需要滚动的高度
        top -= 21;
        // 把每次滚动的高度加起来，如等于自身的高度就判断滚动完毕
        countTop = countTop + 21;
        // 获取需要滚动的内容的高度,转为数字
        let num = parseInt(window.getComputedStyle(this.$refs.boxtext).height);
        // console.log(num, 888);
        // 滚动的大小
        this.$refs.boxtext.style.marginTop = top + "px";
        this.$refs.boxtext.style.transition = "all 0.5s";
        // 如滚动的距离大于等于文本自身的高度，则重新开始滚动，这样一直循环
        if (countTop >= num) {
          top = 0;
          countTop = 0;
          this.$refs.boxtext.style.marginTop = 0;
          this.$refs.boxtext.style.transition = "";
        }
      }, this.time);
    },
  },
  destroyed() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    content: {
      handler(news, old) {
        // console.log(news);
        this.article = news;
        setTimeout(() => {
          this.scroll();
        }, 200);
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang='scss'>
.marquee_box {
  width: 100%;
  overflow: hidden;
  .marquee_text {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
