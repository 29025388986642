import axios from "axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Message } from "element-ui";

//需要区分当前环境(deveLopment)还是生产环境(productione)
let ispro = process.env.NODE_ENV === "production";

const service = axios.create({
  baseURL: ispro ? "https://applet.lvzhouhuamu.cn/api/" : "https://applet.lvzhouhuamu.cn/api/", //119.29.20.70:8888  /api
  timeout: 10000,
});


//请求拦截
service.interceptors.request.use((config) => {
  // nprogress.start()
  // console.log(config.data);
  //配置token token是鉴权的一种方式
  //在登陆成功之后 会返回当前用户的token 存本地
  // let token = sessionStorage.getItem('token')
  // if (token) {
  //   config.headers.Authorization = token
  // }
  return config
}, err => {
  // nprogress.done()
  return Promise.reject(err)
});


//响应拦截
service.interceptors.response.use(
  (res) => {
    // console.log(res);
    // nprogress.done();
// if

    return res.data;
  },
  (err) => {
    // nprogress.done();
    //请求失败
    let stutas = err.response && err.response.status;
    //状态码
    if (stutas === 400) {
      Message.error("参数错误");
    }
    if (stutas === 401) {
      Message.error("没有权限");
    }
    if (stutas === 403) {
      Message.error("登陆过期");
    }
    if (stutas === 404) {
      Message.error("路径错误");
    }
    if (stutas === 500) {
      Message.error("服务器错误");
    }
    if (stutas === 503) {
      Message.error("服务器在维护");
    }
  }
);

export default service
