<template>
  <div class="content">
    <div class="nav">
      <img class="nav_img" src="../assets/index/nav.png" alt="" />
    </div>
    <div class="cont">
      <div class="flex_x">
        <div class="data_img">
          <div class="data_title">最近拨打电话</div>
          <div class="data_tel">
            <scrollNumbers :number="number"></scrollNumbers>
          </div>
        </div>

        <div class="data_img ml42">
          <div class="data_titlea">数据展示</div>
          <div class="data1">
            <div v-for="item in arr" :key="item.id" class="data1_bg" style="">
              <div class="data1_title">{{ item.name }}</div>
              <div class="data1_num">
                <CountTo :startVal="0" :endVal="item.num" :duration="2000" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="consult flex_btw">
        <div class="consult_img">
          <div class="consult_title">咨询信息</div>
          <div class="consult_data">
            <div>
              <animateList :List="enquiry_list">
                <template v-slot:animate="{ Lists }">
                  <div
                    v-for="(item, index) in Lists"
                    :key="index"
                    class="flex_btw mb10"
                  >
                    <div class="flex_c">
                      <img class="consult_data_img" :src="item.avatar" alt="" />
                    </div>
                    <div class="flex_x flex1 ml12">
                      <div class="call">拨打：</div>
                      <div class="calla flex1 flex_x">
                        <div class="sn1">{{ item.goods_name }}</div>
                        <div style="width: 80px">-{{ item.distance }}</div>
                      </div>
                    </div>
                    <div class="check ml10">查看{{ item.time }}</div>
                  </div>
                </template>
              </animateList>
            </div>
          </div>
        </div>
        <div class="consult_img consult_imga">
          <div class="consult_title">求购发布</div>
          <div class="consult_data">
            <div>
              <animateList :List="buy_list">
                <template v-slot:animate="{ Lists }">
                  <div
                    v-for="(item, index) in Lists"
                    :key="index"
                    class="flex_btw mb10"
                  >
                    <div class="flex_c">
                      <img class="consult_data_img" :src="item.avatar" alt="" />
                    </div>
                    <div class="flex_x flex1 ml12">
                      <div class="call">发布：</div>
                      <div class="calla flex1 flex_x">
                        <div class="sn1 flex1">
                          {{ item.goods_name }}
                        </div>
                        <!-- <div>-23.6km</div> -->
                      </div>
                    </div>
                    <div class="check ml10">查看{{ item.time }}</div>
                  </div>
                </template>
              </animateList>
            </div>
          </div>
        </div>
        <div class="consult_img">
          <div class="consult_title">供应发布</div>
          <div class="consult_data">
            <div>
              <animateList :List="supply_list">
                <template v-slot:animate="{ Lists }">
                  <div
                    v-for="(item, index) in Lists"
                    :key="index"
                    class="flex_btw mb10"
                  >
                    <div class="flex_c">
                      <img class="consult_data_img" :src="item.avatar" alt="" />
                    </div>
                    <div class="flex_x flex1 ml12">
                      <div class="call">发布：</div>
                      <div class="calla flex1 flex_x">
                        <div class="sn1 flex1">
                          {{ item.goods_name }}
                        </div>
                        <!-- <div>-23.6km</div> -->
                      </div>
                    </div>
                    <div class="check ml10">查看{{ item.time }}</div>
                  </div>
                </template>
              </animateList>
            </div>
          </div>
        </div>
      </div>

      <div class="tail">
        <div class="taila">
          <div class="tail_title">{{ article_details.title }}</div>

          <textScroll
            :content="article_details.content"
            :time="Number(article_details.speed) * 1000"
          ></textScroll>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import scrollNumbers from "@/components/scrollNumbers/scrollNumbers";
import animateList from "@/components/animateList/animateList";
import textScroll from "@/components/textScroll/textScroll";
import CountTo from "vue-count-to";
export default {
  name: "",
  props: {},
  components: { animateList, textScroll, scrollNumbers, CountTo },
  data() {
    return {
      number: "000000000",
      arr: [
        {
          id: 1,
          name: "入驻用户",
          num: 0,
        },
        {
          id: 2,
          name: "信息发布",
          num: 0,
        },
        {
          id: 3,
          name: "职业经理人",
          num: 0,
        },
        {
          id: 4,
          name: "物流服务",
          num: 0,
        },
        {
          id: 5,
          name: "园林资材",
          num: 0,
        },
        {
          id: 6,
          name: "工程服务",
          num: 0,
        },
      ],
      enquiry_list: [], //咨询列表
      buy_list: [], //求购列表
      supply_list: [], //供应列表

      article_details: "", //文章

      timer: null, //定时器+
    };
  },
  mounted() {},

  created() {
    this.getDataNum();
    clearInterval(this.timer);
    setTimeout(() => {
      this.timer = setInterval(() => {
        this.getDataNum();
      }, 60 * 1000);
    }, 60 * 1000);
  },
  beforeDestroy() {
    //离开页面清除定时器
    clearInterval(this.timer);
  },

  methods: {
    getDataNum() {
      //获取数据
      this.$api.getData().then((res) => {
        if (res.code == 1) {
          this.number = res.data.call_num;
          this.arr[0].num = res.data.user_num;
          this.arr[1].num = res.data.info_num;
          this.arr[2].num = res.data.manager_num;
          this.arr[3].num = res.data.service_num;
          this.arr[4].num = res.data.park_num;
          this.arr[5].num = res.data.project_num;
        }
        this.getData();
      });
    },
    getData() {
      //获取信息
      Promise.all([
        this.enquiry(),
        this.buy(),
        this.supply(),
        this.article(),
      ]).then((res) => {
        console.log(res);
        this.enquiry_list = res[0];
        this.buy_list = res[1];
        this.supply_list = res[2];
        this.article_details = res[3];
      });
    },

    enquiry() {
      //咨询信息
      return new Promise((resolve, reject) => {
        this.$api
          .enquiry()
          .then((res) => {
            if (res.code == 1) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    buy() {
      //求购发布
      return new Promise((resolve, reject) => {
        this.$api
          .buy()
          .then((res) => {
            if (res.code == 1) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    supply() {
      //供应发布
      return new Promise((resolve, reject) => {
        this.$api
          .supply()
          .then((res) => {
            if (res.code == 1) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    article() {
      //文章
      return new Promise((resolve, reject) => {
        this.$api
          .article()
          .then((res) => {
            if (res.code == 1) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  computed: {},

  watch: {},
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-image: url(../assets/index/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .nav {
    margin: 0 172px 0 162px;
    .nav_img {
      width: 100%;
      height: 120px;
    }
  }
  .cont {
    margin: 20px 40px 0 40px;
    // height: 200px;
    // background-color: pink;
    .data_img {
      flex: 1;
      height: 190px;
      background-image: url(../assets/index/bor.png);
      background-repeat: no-repeat;
      background-size: cover;
      .data_title {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #34dcfc;
        position: relative;
        top: -11px;
        left: 5px;
      }
      .data_titlea {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #34dcfc;
        position: relative;
        top: -11px;
        left: 5px;
      }
      .data_tel {
        padding: 6px 20px 20px 20px;
      }

      .data1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // background-color: pink;
        margin-right: 12px;
        height: 98%;
        .data1_bg {
          width: 30%;
          height: 66px;
          background-image: url(../assets/index/databor.png);
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          .data1_title {
            margin-left: 30px;
            padding-bottom: 5px;
            font-size: 14px;
            color: #fff;
          }
          .data1_num {
            margin-left: 70px;
            padding-bottom: 5px;
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }

    .consult {
      margin-top: 30px;
      .consult_img {
        width: 30%;
        height: 350px;
        background-image: url(../assets/index/consul_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        .consult_title {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #34dcfc;
          position: relative;
          top: -11px;
          left: 5px;
        }
        .consult_data {
          margin: 20px 31px 0 27px;
          height: 290px;
          overflow: hidden;
          .consult_data_img {
            width: 50px;
            height: 50px;
            border-radius: 4px;
          }
          .call {
            width: 50px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
          .calla {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #34dcfc;
          }
          .check {
            font-size: 16px;
            font-family: Source Han Sans CN;
            color: #ffffff;
          }
        }
      }
      .consult_imga {
        margin: 0 41px;
      }
    }

    .tail {
      margin-top: 38px;
      background-image: url(../assets/index/tail.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 160px;
      border-top: 1px solid rgba(0, 0, 0, 0);
      overflow: hidden;
      .taila {
        margin: 10px 25px 24px 25px;
        overflow: hidden;
        height: 140px;
        .tail_title {
          text-align: center;
          margin-bottom: 10px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #34dcfc;
        }
        .tail_data {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }
  }
}
</style>