import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import layout from '../views/layout/layout.vue'
import cookies from 'vue-cookies';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "首页"
    },
  },

  // {
  //   path: '/login',
  //   name: "login",
  //   component: () => import('@/views/login/login'),
  //   meta: {
  //     title: "登录",
  //   },
  // },

  // {
  //   path: '',
  //   name: '',
  //   component: layout,
  //   children: [
  //     {
  //       path: "*",
  //       name: "",
  //       component: () => import('@/views/error/404'),
  //       meta: {
  //         title: "页面不存在",
  //       },
  //     },
  //   ]
  // },

]

const router = new VueRouter({
  mode: 'hash', //hash history
  base: process.env.BASE_URL,
  routes
})


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title; //动态改变浏览器标题
  next()
  // if (to.path === "/login") {
  //   //当进入的就是登陆页面或者注册页面时，不做操作
  //   next();
  // } else {
  //   if (cookies.get('token')) {
  //     //同过登陆成功时存储在本地用户名，来判断用户是否成功登陆
  //     next();
  //   } else {
  //     next("/login");
  //   }
  // }
});

export default router
