import http from "./index";

export default {

  getData() { //数据
    return http.get("data/index");
  },

  enquiry() {  //咨询信息
    return http.get("data/enquiry");
  },
  buy() { //求购发布
    return http.get("data/buy");
  },
  supply() { //供应发布
    return http.get("data/supply");
  },
  article() { //文章
    return http.get("data/article");
  },

};
